@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/qru5hxv.css");


html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  scroll-behavior: smooth;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: costa, sans-serif;
    font-weight: 400;
    font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: costa, sans-serif;
  font-weight: 400;
  font-style: normal;
}
